import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Product } from "app/entities";
import { Item } from "app/entities/item";
import { Unit } from "app/entities/unit";
import { ProductsService } from "app/main/pages/products/product.service";
import { AlertService } from "app/services/alert.service";
import { LocalStorageService } from "app/services/local-storage.service";
import { SingletonsService } from "app/services/singletons.service";
import { ReplaySubject } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
    selector: "app-proposal-add-item",
    templateUrl: "./proposal-add-item.component.html",
    styleUrls: ["./proposal-add-item.component.scss"],
})
export class ProposalAddItemComponent implements OnInit, AfterViewInit {
    @ViewChild("itemName") itemName?: ElementRef;

    form: FormGroup;

    item: Item;

    index: number;

    units;
    defaultUnit;
    shouldSave = false;

    filteredProducts: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);
    products: Product[] = [];

    constructor(
        public matDialogRef: MatDialogRef<ProposalAddItemComponent>,
        private _localStorageService: LocalStorageService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _singletonService: SingletonsService,
        private _productService: ProductsService,
        private _alertService: AlertService
    ) {
        this.index = this._data.index;
        this.item = this._data.item;
        // console.log(this.item);

        this.form = this._formBuilder.group({
            item_id: [this.item.id],
            item_name: [this.item.name, [Validators.required]],
            item_unit_id: [this.item.unitId],
            item_per_unit: [this.item.perUnit],
            item_quantity: [this.item.quantity || 1],
            ordem: [this.item.ordem || 1],
            should_save: [this.item.shouldSave],
            product_id: [this.item.productId],
        });

        this._productService.simpleProductsAll().subscribe((products) => {
            if (products.data) this.products = products.data;
            this.filteredProducts.next(this.products.slice());
            this.form.get('item_name')?.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.title),
                map(name => name ? this._filter(name) : this.products.slice())
            ).subscribe(filteredProducts => {
                this.filteredProducts.next(filteredProducts);
                if (filteredProducts.length == 0) {
                    if (this.item.shouldSave) this.form.get('should_save')?.setValue(this.item.shouldSave);
                    else this.form.get('should_save')?.setValue(false);
                    this.form.get('product_id')?.setValue(null);
                } else {
                    if (this.item.shouldSave) this.form.get('should_save')?.setValue(this.item.shouldSave);
                    else this.form.get('should_save')?.setValue(null);
                }
            });
        });
    }

    cancel() {
        this.matDialogRef.close({ action: "cancel" });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.itemName?.nativeElement.focus();
        }, 300);
    }
 
    ngOnInit(): void {
        this.defaultUnit = this._localStorageService.getItem(
            Unit.defaultUnitKey
        );
        this._singletonService.getUnits().subscribe((units) => {
            this.units = units;
            if (this.defaultUnit == null) {
                this.defaultUnit = this.units[0];
            }
        });
    }

    updateFilteredProducts(currentValue: string) {
        if (currentValue) {
            const filteredProducts = this._filter(currentValue);
            setTimeout(() => {
                this.filteredProducts.next(filteredProducts);
            }, 100);
        } else {
            this.filteredProducts.next(this.products.slice());
        }
    }

    setProduct(event: MatAutocompleteSelectedEvent) {
        const selectedProduct: Product = event.option.value;
        
        this.form?.patchValue({
            'should_save': null,
            'product_id': selectedProduct.id,
            'item_unit_id': selectedProduct.unitId,
            'item_per_unit': selectedProduct.valor,
            'item_name': selectedProduct.title,
        });

        if (!selectedProduct.unitId) {
            this.form?.patchValue({
                'item_unit_id': this.defaultUnit?.id,
            });
        }
    }

    getFilteredLenght() {
        let itemName = this.form.get('item_name')?.value;
        if (!itemName) return -1;   
        return this._filter(itemName).length;
    }

    showFeedBackItemSaveMessage(isSaving) {
        if (isSaving) {
            this._alertService.success(
                "O item será salvo na sua lista de produtos."
            );
        } else {
            this._alertService.success(
                "O item não será salvo na sua lista de produtos."
            );
        }
        this.form?.get('proposal_config_save_item')?.setValue(isSaving);
    }
    
    onIconClick(event: MouseEvent) {
        event.stopPropagation();
        
        const currentValue = this.form?.get('should_save')?.value;
        this.form?.get('should_save')?.setValue(!currentValue);
        this.shouldSave = !currentValue;
        this.showFeedBackItemSaveMessage(!currentValue);
    }
    
    private _filter(name: string): Product[] {
        const filterValue = name.toLowerCase();
        return this.products.filter(product => {
            let productCode = product?.code?.toLowerCase();
            let productTitle = product?.title?.toLowerCase();
            let productCodeAndTitle = "#"+productCode + ' - ' + productTitle;
            return productCode?.includes(filterValue) || productTitle?.includes(filterValue) || productCodeAndTitle?.includes(filterValue);
        });
    }

    saveItem() {
        var values = this.form.value;
        this.matDialogRef.close({
            action: "save",
            item: Object.assign(new Item(), {
                id: values.item_id,
                name: values.item_name,
                unitId: values.item_unit_id,
                perUnit: values.item_per_unit,
                quantity: values.item_quantity,
                productId: values.product_id,
                shouldSave: values.should_save,
            }),
            index: this.index,
        });
    }

    getUnitById(id) {
        if (this.units != null && this.units.length > 0) {
            for (let index = 0; index < this.units.length; index++) {
                if (this.units[index].id == id) {
                    return this.units[index];
                }
            }
        }
        return null;
    }

    getUnitNameById(id) {
        return this.getUnitById(id)?.name;
    }

    getUnitSuffixById(id) {
        var unit = this.getUnitById(id);
        if (unit?.type == Unit.TYPE_PERCENT) {
            return unit.abbr;
        }
    }

    getTotal() {
        var total = this.getTotalValue();
        return total.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    getTotalValue() {
        var item = this.form.value;
        var unit = this.getUnitById(item.item_unit_id);
        var total;
        if (!unit) return 0;
        if (unit?.type == Unit.TYPE_PERCENT && item.item_per_unit > 0) {
            total = (item.item_per_unit / 100) * item.item_quantity;
        } else {
            total = item.item_per_unit * item.item_quantity;
        }
        return total ?? 0;
    }

    removeItem() {}
}
