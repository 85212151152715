import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UserSimple } from 'app/entities';
import { Proposal } from 'app/entities/proposal';
import { ProposalService } from 'app/main/pages/proposals/proposals.service';
import { UsersService } from 'app/main/pages/users/users.service';
import { AlertService } from 'app/services/alert.service';
import { AuthService } from 'app/services/auth.service';


@Component({
    selector: 'app-proposal-information',
    templateUrl: './proposal-information.component.html',
    styleUrls: ['./proposal-information.component.scss']
})
export class ProposalInformationComponent implements OnInit {

    @Input() proposal!: Proposal;
    @Input() editavel?: boolean;
    @Input() loading?: boolean;

    @Output() onProposalChanged = new EventEmitter();

    form: FormGroup = new FormGroup({});

    proposalStatus = [
        { value: 1, label: 'Criada', 'class': 'status-created' },
        { value: 2, label: 'Enviada', 'class': 'status-send' },
        { value: 4, label: 'Recusada', 'class': 'status-refused' },
        { value: 3, label: 'Aprovada', 'class': 'status-approved' },
        { value: 5, label: 'Faturada', 'class': 'status-billed' },
        { value: 6, label: 'Paga', 'class': 'status-payed' },
        { value: 7, label: 'Expirada', 'class': 'status-expired' },
    ]

    user?: User | null;
    customers?: User[] | null = []

    selectedStatus?: any;
    selectedCreator?: User | null = null;
    selectResponsible?: User | null = null;

    canEditResponsible = false;
    canEditStatus = false;

    constructor(
        private _formBuilder: FormBuilder,
        private _authService: AuthService,
        private _userService: UsersService,
        private _proposalService: ProposalService,
        private _alertService: AlertService,
    ) { }

    ngOnInit(): void {
        this.user = this._authService.currentUser
        this.canEditResponsible = this.user?.roleName === 'admin'
        this.canEditStatus = this.user?.roleName === 'admin' || this.user?.roleName === 'commercial'

        this.loadCustomers()

        this.selectedStatus = this.proposalStatus.find(s => s.value === this.proposal.status);
        this.createForm()

    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
        
        if (changes['proposal']?.currentValue != changes['proposal']?.previousValue) {
            console.log(changes['proposal']);
            
            this.proposal = changes['proposal'].currentValue;
            const status = this.proposalStatus.find(s => s.value === this.proposal.status);
            this.selectedStatus = status;

            this.form.patchValue({
                status: status?.value,
            });

            

        }
    }

    loadCustomers(): void {
        this._userService.simpleUsuariosAll().subscribe((customers) => {
            this.customers = customers;
            this.selectedCreator = this.customers?.find(u => u.id === this.proposal.creatorId);
            this.selectResponsible = this.proposal.customerId ? this.customers?.find(u => u.id === this.proposal.customerId) : null;
        })
    }



    createForm(): void {

        this.form = this._formBuilder.group({
            status: [this.proposal.status, [Validators.required]],
            refused_at: [this.proposal.refusedAt, []],
            approved_at: [this.proposal.approvedAt, []],
            billed_at: [this.proposal.billedAt, []],
            paid_at: [this.proposal.paidAt, []],
            creator_id: [this.proposal.creatorId, []],
            customer_id: [this.proposal.customerId, []],
        });
    }

    

    assignToMeResponsible(): void {
        if(!this.canEditResponsible){
            return;
        }
        
        this.form.patchValue({
            customer_id: this.user?.id,
        });

        this.form.markAsDirty();

        this.selectResponsible = this.customers?.find(u => u.id === this.user?.id);
    }

    statusChanged(event: any): void {
        if(!this.canEditStatus) return;

        const status = this.proposalStatus.find(s => s.value === event.value);
        this.selectedStatus = status;

        this.form.patchValue({
            status: status?.value
        });
    }

    responsibleChanged(event: any): void {
        console.log(event);
        
        this.selectResponsible = this.customers?.find(u => u.id === event.value);
    }

    formUpdated(proposal:Proposal): void {
        this.form.patchValue({
            status: proposal.status,
            refused_at: proposal.refusedAt,
            approved_at: proposal.approvedAt,
            billed_at: proposal.billedAt,
            paid_at: proposal.paidAt,
            creator_id: proposal.creatorId,
            customer_id: proposal.customerId,
        });

        this.selectResponsible = this.customers?.find(u => u.id === proposal.customerId);
        this.selectedCreator = this.customers?.find(u => u.id === proposal.creatorId);

        // this.form.markAsDirty();
    }

    save(): void {
        if (!this.form.valid || !this.form.dirty || !this.proposal.id) {
            return;
        }

        this.loading = true;
        this._proposalService.updateInformation(this.proposal.id!, this.form.value).subscribe((proposal) => {
            this.loading = false;
            this.formUpdated(proposal);
            
            this.onProposalChanged.emit(proposal);

        }, (e) => {
            this._alertService.error(e);
            this.loading = false;
        });
    }
}
